var sm = $(window).width() < 768;
var md = $(window).width() > 768;
var lg = ($( window ).width() > 1024);

document.fonts.ready.then(function() {
    windowresize();
    $('header').show();

    console.log('js works');

    $('.lazy').lazy();

    $('.right-col').scroll(function() {
            $('.right-col .lazy').lazy({
                appendScroll: $('.right-col'),
            });
    });

    var last = 0, diff;
    $('.anchor').click(function(e){

        diff = event.timeStamp - last;
        if(diff < 500){
            return false;
        }
        e.preventDefault();
        e.stopPropagation();

        console.log(diff);

        var $container = $('.right-col'),
            target = $(this).attr('href');
        console.log(target);
        $scrollTo = $('#'+target);

        $container.animate({
            scrollTop: $scrollTo.offset().top - $container.offset().top + $container.scrollTop()+1
        },1000,"swing")

        if($(window).width() <= 768) {
            $('.menu-wrap').removeClass('collapsed').fadeOut(100);
        }

        last = event.timeStamp;
    });

    $('button').click(function(e) {

        $(this).toggleClass('open');
        $('.menu-wrap').toggleClass('collapsed');

        if($('.menu-wrap').hasClass('collapsed')){
            $('.menu-wrap').fadeIn(100);
        }
        else{
            $('.menu-wrap').fadeOut(100);
        }
    });

    $(window).resize(function(){

        windowresize();

    })


});




function windowresize() {

    if($(window).width() <= 768){
        $('button').removeClass('open');
        $('.menu-wrap').removeClass('collapsed');
        $('.menu-wrap').hide();
    }else{
        $('button').removeClass('open');
        $('.menu-wrap').removeClass('collapsed');
        $('.menu-wrap').show();
    }

};

